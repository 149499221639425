import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import TableSpinner from "../../custom/spinner/TableSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { UserDateFormat } from "../../../utilities/DateFormate";
import { useNavigate } from "react-router-dom";
import { yearDate } from "../../../utilities/DateFormate";
const PatientListForModel = ({ patientdata, updatePagination, orgId, allPatients, setPatientarray }) => {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const navigate = useNavigate();

  function handleEdit(akId) {
    // let name = na;
    // let age = yearDate(ag);
    navigate(`/add-operative-notes/?akid=${window.btoa(akId)}&orgId=${window.btoa(orgId)}&ptshow=true`); //?akid=QUtQVDAwMDA0OQ==&orgId=QUtPVTAwMDA5OA==&ptshow=true
  }
  const data = patientdata;
  const [selectedRows, setSelectedRows] = useState([]);
  const [patientid, setpatientid] = useState("")
  
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows(prevSelectedRows => [...prevSelectedRows, id]);
    } else {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter((rowId) => rowId !== id));
    }
  };
  
  let patients=allPatients?.map((item)=>item.value)
  useEffect(() => {
    if (!selectedRows.includes(patientid)) {
      setSelectedRows(prevSelectedRows => [...prevSelectedRows, patientid]);
    } else if (selectedRows.includes(patientid)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter((rowId) => rowId !== patientid));
    }
  }, [patientid]);
  useEffect(()=>{
    setSelectedRows([...selectedRows,...patients]);

  },[patients.length])
  useEffect(() => {
    setPatientarray(selectedRows)
  }, [selectedRows.length])
  console.log(selectedRows, "selectedRows",patientid);
  const columns = React.useMemo(
    () => [
      // {
      //     Header: "",
      //     id: "3",
      //     Cell: (row) => (

      //     ),
      //   },
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "name",
        Cell: (row) => (
          <>
            <div className="d-flex">
              <input
                type="checkbox"
                className="me-3"
                onChange={(e) =>
                  handleCheckboxChange(e, row.row.original.ak_id)
                }
              // disabled={true}
                defaultChecked={allPatients.filter((item)=>item.value===row.row.original.ak_id).length!==0?true:false}
              />

              <span>
                <label className="user-letter small">
                  {row.value.charAt(0).toUpperCase()}
                </label>
              </span>
            </div>
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "DOB",
        accessor: "dob",

      },
      {
        Header: "AKPT ID",
        accessor: "ak_id",
      },
      //   {
      //     Header: "Email",
      //     accessor: "ea_mail",
      //     className: "text-lowercase w-50",
      //   },
      // {
      //   Header: "Doctor",
      //   accessor: "ea_doc",
      // },
      // {
      //   Header: " Organization",
      //   accessor: "ea_org",
      //   headerClassName: "col-action",
      // },
      //   {
      //     Header: "Action",
      //     headerClassName: "col-action pl45",
      //     accessor: "action",
      //     className: "col-action",
      //     Cell: (row) => (
      //       <span>
      //         <button
      //           className="btn_fill"
      //           onClick={(e) => handleEdit(row.row.original.ak_id)}
      //         >
      //           Add Note
      //         </button>
      //       </span>
      //     ),
      //   },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <div className="tableContainer" id="rpScrollableDivModel">
      <InfiniteScroll
        dataLength={rows.length}
        next={updatePagination}
        hasMore={true}
        scrollableTarget="rpScrollableDivModel"
      // loader={<TableSpinner />}
      >
        <table {...getTableProps()} className="custom-tbl">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.headerClassName },
                    ])}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default PatientListForModel;
