import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import InputField from "../../custom/inputfield/InputField";
import useAxios from "../../../axiosinstance";
import { useSelector,useDispatch } from "react-redux";
import useTranslation from "../../customHooks/translations";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import Dropdown from "../../custom/dropdown/Dropdown";
import PatientListForModel from "./PatientListForModel";
import Button from "../../custom/button/Button";
import Message from "../../custom/toster/Message";
import { noteRefresh } from '../../../redux/actions';
import { useSearchParams } from "react-router-dom";
const PatientModel = ({ modelOpen, setShowModal, type ,orgid,allPatients,setOrgid,rule_id}) => {
  const axiosInstance = useAxios();
  const translation = useTranslation();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [firstName, setFirstName] = useState("");
  const dispatch =useDispatch()
  const [lastName, setLastName] = useState("");
  const [patientdata, setData] = useState([]);
  const [email] = useState("");
  const [startDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [startDate, setStartDate] = useState(new Date());
  const [lastTypingTime, setLastTypingTime] = useState(null);

  const [offset, setOffset] = useState(10);
  const [limit] = useState(10);
  // const [sortType] = useState("last_created");
  const [org, selectOrg] = useState(orgid);

  const [, setTotaldata] = useState("");

  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchEnable, setsearchEnable] = useState("");
  let searchType = searchParams.get("search");
  const removeQueryParams = () => {
    const param = searchParams.get("q");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("q");
      searchParams.delete("search");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };


  const advsearchToFirstName = (event) => {
    setLastTypingTime(new Date().getTime());
    setFirstName(event);
    removeQueryParams();
    if (event === "") {
    
    } else {
      setsearchEnable("N");
    }
    setOffset(50);
  };
  const advsearchTolastName = (e) => {
    setLastTypingTime(new Date().getTime());
    setLastName(e);
    removeQueryParams();
    if (e === "") {
     
    } else {
      setsearchEnable("N");
    }
    setOffset(50);

  };
  const [dataload, setDataload] = useState(true)

  const handleOrg = (e) => {
    setLastTypingTime(new Date().getTime());
    selectOrg(e);
  };

  const handleClose = () => {
    setFirstName("")
    setLastName("")
    setShowModal(false);
    setOrgid("")
  };
  const [Reff,setReff]=useState('')
  useEffect(() => {
    if (typeof searchType === "object") {
      setsearchEnable("N");
    }
  }, [searchType]);
  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extapp/patients/patientlists/${login.ing_ak_id}/${orgid}?limit=${limit}&offset=0&f_name=${firstName}&l_name=${lastName}`
            );
            // setDataload(true)
            setData(response.data.data);
            setTotaldata(response.data.total);
            
          } catch (error) {
          } finally {
            setDataload(false)
            setIsLoading(false);
          }
        };
        getData();
      }, 1000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }
  }, [firstName, lastName, orgid]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
console.log(orgid,"orgidorgid")
  useEffect(() => {

    const getData = async () => {
      try {
        setDataload(true)
        const response = await axiosInstance.current.get(
          `extapp/patients/patientlists/${login.ing_ak_id}/${orgid}?limit=${limit}&offset=0&f_name=${firstName}&l_name=${lastName}`
        );

        setData(response.data.data);
        setTotaldata(response.data.total);
      } catch (error) {
      } finally {
        setDataload(false)
      }
    };
    if(orgid && modelOpen){
      getData();
    }
   
  }, [orgid,modelOpen]);



  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/patientlists/${login.ing_ak_id}/${orgid}?limit=${limit}&offset=${offset}&f_name=${firstName}&l_name=${lastName}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(patientdata.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };
  useEffect(() => {
    setOffset(10);
    selectOrg("");
    setData([])
    // setDataload(true)
  }, [modelOpen]);

  const[Patientarray,setPatientarray]=useState([])
let json=JSON.stringify({
    patient_ids:Patientarray
})
  const Adddata=async()=>{
    try{
        const response = await axiosInstance.current.put(`/extapp/doctors/updatePatient?rule_id=${rule_id}&ak_id=${login.ing_ak_id}`,json,
        {
          headers: {
            "Content-Type": "application/json",
          },})
          if(response){
            dispatch(noteRefresh(new Date().getMilliseconds()));
            // setReff(new Date().getMilliseconds())
            setMessageType("success");
            setMessage("Patient add successful"); 
           
          }
          setTimeout(() => {
            setOrgid("")
            setMessage(false);
            setShowModal(false)
            setReff("")
          }, 3000);
        }catch(err){

        }
  }

  console.log(dataload,"this is dataload");
  return (
    <>
     {message && <Message message={message} type={messageType} />}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Modal
          show={modelOpen}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-lg patient_notes_popup modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Patient List</Modal.Title>
            
            {Patientarray.length>0 &&(
              <Button
                    onClick={() =>Adddata()}
                    value="Save"
                    buttonStyle="btn_fill mr_20 width-6"
                  />
            )}
          </Modal.Header>
          <Modal.Body className="">
          <div className="row">
          <div className="col-6 ">
                    <InputField
                      label="First Name"
                      value={firstName}
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      onChange={advsearchToFirstName}
                    />
                    </div>
                    <div className="col-6 ">
                    <InputField
                      label="Last Name"
                      value={lastName}
                      type="text"
                      placeholder="Last Name"
                      onChange={advsearchTolastName}
                    />
                  </div>
                  </div>

            <div>
          
              <div>
                {dataload ? <div className="no_data">
                      <div class="spinner-border " role="status" style={{color:"#3f2783"}}>
                        <span class="visually-hidden">Loading...</span>
                      </div> 
                    </div> 
                 : (
                  <> 
                    {dataload ? <div className="no_data">
                      <div class="spinner-border " role="status" style={{color:"#3f2783"}}>
                        <span class="visually-hidden">Loading...</span>
                      </div> 
                    </div> : (
                      <>
                        {patientdata?.length > 0 ? (
                          <PatientListForModel
                            patientdata={patientdata}
                            updatePagination={fetchMoreData}
                            orgId={org}
                            allPatients={allPatients}
                            setPatientarray={setPatientarray}
                          />
                        ) : (
                          <div className="no_data">{translation.No_data_found}</div>
                        )}</>
                    )}
                  </>
                )}



              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PatientModel;
