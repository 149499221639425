import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import CreatableSelect from "react-select/creatable";
import addOpStyles from "./../operative-notes/add-operative-notes/AddOperativeNotes.module.css";
import { convertToDays } from "../../utilities/convertToDays";
import SmallProfilescheduler from "../settings/Proms/SmallProfilescheduler";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { noteRefresh } from "../../redux/actions";
import Button from "../custom/button/Button";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import DropdownList from "./DropdownList";
import ButtonLoader from "../custom/button/ButtonLoader";

export default function WidgetScheduler({
  showModal1,
  onSave,
  pop,
  popupshow,
  setshowModal1,
  PostOpNoteshow,
  personalData,
  fullname,
  tAge,
  orgId,
  schedulerOrg,
  resId,
  rowOrgId,
  ptId,
  rowId,
  type,
  setshowModal,
  resourceID,
  ak_pt,
  resource_num,
  ppShow,
  BlockReference,
  blockupdate,
  setKkkk,
  type2,
  side,
  opSide,
  setIsLoadingdata,
  isLoadingdata,
  setgetOpNotecall,
  usedOpNote,
}) {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [repeatInterval, setRepeatInterval] = useState("");
  const [NonsurgicalInput, setNonsurgicalInput] = useState();
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState(false);
  const refresh = useSelector((state) => state.mainReducer.noteRef);
  const patientDetails = useSelector(
    (state) => state.mainReducer.patientDetails
  );

  const dispatch = useDispatch();
  // New all changes
  const [SelectOrg, setSelectOrg] = useState([]);
  const [Selectspecialty, setSelectspecialty] = useState();
  const [Selecttreatment, setSelecttreatment] = useState("");
  // const [SelectAlert, setSelectAlert] = useState([
  //   { value: 0, label: "No alert" },
  // ]);
  const [SelectAlertOver, setSelectAlertOver] = useState([
    { value: 1, label: "1 day after" },
  ]);
  const [SelectReferdate, setSelectReferdate] = useState([]);
  const [orgdata, setOrgdatav] = useState([]);
  const [typeData, setTypeData] = useState("");
  const [primary, setPrimary] = useState("");
  const [err, setErr] = useState(false);
  const [setSheduleerr, setScheduleErr] = useState(false);
  const [resourceId, setresourceId] = useState("");
  const [isApiPending, setIsApiPending] = useState(false);
  // const { ptId } = useParams();
  const [showModals, setshowModals] = useState(false);
  const [checkdata, setAssignCheck] = useState([]);
  const [notresetspecialty, setNotresetspecialty] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const { akid } = useParams();
  const [submitdata, setSubmitdata] = useState("");
  const [Othersinputfield, setOthersinputfield] = useState("");
  let data = orgdata.filter((item) => item.value === schedulerOrg);

  useEffect(() => {
    if (data) {
      let nn = data.map((item) => {
        return {
          label: item.label,
          value: item.value,
          isDisabled: true,
        };
      });
      setSelectOrg(nn);
    }
  }, [data.length]);
  let orgid = rowOrgId ? rowOrgId : orgId;
  let akpt = rowId ? rowId : ptId;
  localStorage.setItem("patient_name", fullname);
  localStorage.setItem("patient_age", tAge);
  const handleprimary = (e) => {
    setPrimary(e);
  };
  const handleOthersinputfield = (e) => {
    setOthersinputfield(e);
  };
  const handleNonsurgicalInput = (e) => {
    setNonsurgicalInput(e);
  };
  const HandleSelectReferdate = (e) => {
    setSelectReferdate(e);
  };

  const [referenceDate, setRefeDate] = useState([
    { value: 1, label: "Treatment Date" },
    { value: 2, label: "Date of initial PROMs" },
  ]);

  const [referenceDate1, setRefeDate1] = useState([
    { value: 1, label: "Treatment Date" },
  ]);
  const [referenceDate2, setRefeDate2] = useState([
    { value: 2, label: "Date of initial PROMs" },
  ]);

  // const [alertOptions, setalertOptions] = useState([
  //   { value: 0, label: "No alert" },
  //   { value: 1, label: "Same day" },
  //   { value: 2, label: "1 day before" },
  //   { value: 3, label: "2 days before" },
  //   { value: 4, label: "1 week before" },
  // ]);

  const [alertOverdueOptions, setalertOverdueOptions] = useState([
    { value: 0, label: "No alert" },
    { value: 1, label: "1 day after" },
    { value: 2, label: "2 days after" },
    { value: 3, label: "1 week after" },
  ]);

  // const HandleSelectAlert = (selectedOptions) => {
  //   setSelectAlert(selectedOptions);
  // };
  // const isNoAlertSelected = SelectAlert?.some((option) => option.value === 0);
  // useEffect(() => {
  //   if (isNoAlertSelected) {
  //     setalertOptions([{ value: 0, label: "No alert" }]);
  //     setSelectAlert([{ value: 0, label: "No alert" }]);
  //   } else {
  //     setalertOptions([
  //       { value: 0, label: "No alert" },
  //       { value: 1, label: "Same day" },
  //       { value: 2, label: "1 day before" },
  //       { value: 3, label: "2 days before" },
  //       { value: 4, label: "1 week before" },
  //     ]);
  //   }
  // }, [isNoAlertSelected]);

  const HandleSelectAlertOver = (selectedOptionsOverdue) => {
    setSelectAlertOver(selectedOptionsOverdue);
  };
  const isNoAlertSelectedoverdue = SelectAlertOver?.some(
    (option) => option.value === 0
  );
  useEffect(() => {
    if (isNoAlertSelectedoverdue) {
      setalertOverdueOptions([{ value: 0, label: "No alert" }]);
      setSelectAlertOver([{ value: 0, label: "No alert" }]);
    } else {
      setalertOverdueOptions([
        { value: 0, label: "No alert" },
        { value: 1, label: "1 day after" },
        { value: 2, label: "2 days after" },
        { value: 3, label: "1 week after" },
      ]);
    }
  }, [isNoAlertSelectedoverdue]);

  const HandleSelecttreatment = (e) => {
    setSelecttreatment(e);
  };
  const HandleSelectspecialty = (e) => {
    setSelectspecialty(e);
    setNotresetspecialty(e);
  };
  const HandleSelectOrg = (e) => {
    setSelectOrg(e);
  };
  const HandleSchedularopen = () => {
    setshowModal1(true);
  };
  const handleRepeatIntervalChange = (e) => {
    setRepeatInterval(e);
  };

  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
    field7: "",
    field8: "",
    field9: "",
    field10: "",
  });

  const [dropdownValues, setDropdownValues] = useState({
    dropdown1: "",
    dropdown2: "",
    dropdown3: "",
    dropdown4: "",
    dropdown5: "",
    dropdown6: "",
    dropdown7: "",
    dropdown8: "",
    dropdown9: "",
    dropdown10: "",
  });

  // let alerts = SelectAlert?.map((item) => item.value);
  let alertsover = SelectAlertOver?.map((item) => item.value);

  const Schedulerjson = {
    ak_id: login.ing_ak_id,
    title: repeatInterval,
    organization: SelectOrg,
    speciality: Selectspecialty,
    treatment_category: Selecttreatment,
    primary_diagnosis: {
      primary: primary,
      Others_inputfield: Othersinputfield,
      non_surgicalinput: NonsurgicalInput,
    },
    alert: [1],
    overdue: alertsover,
    reference_date: [SelectReferdate],
    schedules: [
      {
        value: formData.field1,
        varient: dropdownValues.dropdown1,
        guid: convertToDays(formData.field1, dropdownValues.dropdown1),
      },
      {
        value: formData.field2,
        varient: dropdownValues.dropdown2,
        guid: convertToDays(formData.field2, dropdownValues.dropdown2),
      },
      {
        value: formData.field3,
        varient: dropdownValues.dropdown3,
        guid: convertToDays(formData.field3, dropdownValues.dropdown3),
      },
      {
        value: formData.field4,
        varient: dropdownValues.dropdown4,
        guid: convertToDays(formData.field4, dropdownValues.dropdown4),
      },
      {
        value: formData.field5,
        varient: dropdownValues.dropdown5,
        guid: convertToDays(formData.field5, dropdownValues.dropdown5),
      },
      {
        value: formData.field6,
        varient: dropdownValues.dropdown6,
        guid: convertToDays(formData.field6, dropdownValues.dropdown6),
      },
      {
        value: formData.field7,
        varient: dropdownValues.dropdown7,
        guid: convertToDays(formData.field7, dropdownValues.dropdown7),
      },
      {
        value: formData.field8,
        varient: dropdownValues.dropdown8,
        guid: convertToDays(formData.field8, dropdownValues.dropdown8),
      },
      {
        value: formData.field9,
        varient: dropdownValues.dropdown9,
        guid: convertToDays(formData.field9, dropdownValues.dropdown9),
      },
      {
        value: formData.field10,
        varient: dropdownValues.dropdown10,
        guid: convertToDays(formData.field10, dropdownValues.dropdown10),
      },
    ],
  };

  const handleClose = () => {
    // setshowModal(false)
    setshowModal1(false);
    setIsApiPending(false);
    setRepeatInterval("");
    setSelectOrg([]);
    setSelectspecialty("");
    setSelecttreatment("");
    setNonsurgicalInput("");
    setPrimary("");
    setOthersinputfield("");
    // setSelectAlert([{ value: 0, label: "No alert" }]);
    setSelectAlertOver([{ value: 1, label: "1 day after" }]);
    setSelectReferdate("");
    setFormData({
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      field6: "",
      field7: "",
      field8: "",
      field9: "",
      field10: "",
    });
    setDropdownValues({
      dropdown1: "",
      dropdown2: "",
      dropdown3: "",
      dropdown4: "",
      dropdown5: "",
      dropdown6: "",
      dropdown7: "",
      dropdown8: "",
      dropdown9: "",
      dropdown10: "",
    });
    setErr(false);
    if (visibleInput) {
      setVisibleInput(true);
    }
    setScheduleErr(false);
  };

  useEffect(() => {
    if (resourceId && checkdata?.length === 0) {
      handleClose();
    }
  }, [resourceId]);
  useEffect(() => {
    if (resourceId) {
      if (checkdata?.length !== 0) {
        setshowModals(true);
      }
    }
  }, [checkdata, resourceId]);

  const resetFormState = () => {
    setRepeatInterval("");
    setSelectOrg([]);
    setSelectspecialty("");
    setSelecttreatment("");
    setNonsurgicalInput("");
    setPrimary("");
    setOthersinputfield("");
    // setSelectAlert([{ value: 0, label: "No alert" }]);
    setSelectAlertOver([{ value: 1, label: "1 day after" }]);
    setSelectReferdate("");
    setFormData({
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      field6: "",
      field7: "",
      field8: "",
      field9: "",
      field10: "",
    });
    setDropdownValues({
      dropdown1: "",
      dropdown2: "",
      dropdown3: "",
      dropdown4: "",
      dropdown5: "",
      dropdown6: "",
      dropdown7: "",
      dropdown8: "",
      dropdown9: "",
      dropdown10: "",
    });
    setErr(false);
    setScheduleErr(false);
  };

  const validateForm = () => {
    let hasError = false;
    // Validate dropdown fields
    for (let i = 1; i <= visibleInput; i++) {
      if (!dropdownValues[`dropdown${i}`]) {
        hasError = true;
        break;
      }
    }
    // Validate input fields
    for (let i = 1; i <= visibleInput; i++) {
      if (!formData[`field${i}`]) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };
  const [orgdatava, setOrgdatava] = useState("");
  const [Akdc, setAkdc] = useState("");
  const [autoid, setAuto] = useState("");

  const handleSave = async () => {
    if (repeatInterval === "") {
      setErr(true);
      setshowModal1(true);
      return;
    }
    if (SelectOrg.length === 0) {
      setErr(true);
      setshowModal1(true);
      return;
    }
    if (Selectspecialty === "") {
      setErr(true);
      setshowModal1(true);
      return;
    }
    if (Selecttreatment === "") {
      setErr(true);
      setshowModal1(true);
      return;
    }
    // if (
    //   (Selecttreatment === "Surgical" && Selectspecialty !== "33") ||
    //   (Selecttreatment === "Research" && Selectspecialty !== "33")
    // ) {
    //   if (primary === "") {
    //     setshowModal1(true);
    //     setErr(true);
    //     return;
    //   }
    // }
    // if (
    //   Selecttreatment === "Non-Surgical" ||
    //   (Selecttreatment === "Surgical" && Selectspecialty === "33") ||
    //   (Selecttreatment === "Research" && Selectspecialty === "33")
    // ) {
    //   if (NonsurgicalInput === "") {
    //     setErr(true);
    //     setshowModal1(true);
    //     return;
    //   }
    // }
    if (SelectReferdate === "") {
      setErr(true);
      setshowModal1(true);
      return;
    }
    if (Schedulerjson.schedules) {
      let filteredData = Schedulerjson.schedules.filter(
        (item) => !(item.guid === 0 && item.value === "")
      );
      const hasConsecutiveSameGuidAndValue = filteredData
        .slice(0, -1)
        .some(
          (item, index) =>
            item.guid === filteredData[index + 1].guid &&
            item.value === filteredData[index + 1].value
        );
      const hasMatchingGuidAndValue = filteredData.some((item, index) =>
        filteredData
          .slice(index + 1)
          .some(
            (otherItem) =>
              item.guid === otherItem.guid && item.value === otherItem.value
          )
      );
      if (hasMatchingGuidAndValue) {
        setScheduleErr(hasMatchingGuidAndValue);
        return;
      } else {
        setScheduleErr(false);
      }
    }
    // if (formData === "") {
    //   setErr(true);
    //   setshowModal1(true);
    //   return;
    // }
    // if (dropdownValues === "") {
    //   setErr(true);
    //   setshowModal1(true);
    //   return;
    const formHasError = validateForm();
    if (formHasError) {
      setErr(true);
      setshowModal(true);
      return;
    } else {
      setIsApiPending(true);
      try {
        const response = await axiosInstance.current.post(
          `extapp/doctors/proms_clinicians_schedulerInsert?ak_dc=${login.ing_ak_id}`,
          Schedulerjson,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          setSubmitdata("");
          setIsApiPending(false);
          setresourceId(response.data.auto_id);
          setOrgdatava(response.data.akou_id);
          setAkdc(response.data.ak_dc);
          setAuto(response.data.auto_id);
          handleSaveTask2(Number(response.data.auto_id));
          if (ppShow) {
            if (checkdata?.length === 0) {
              handleCreate(
                response.data.auto_id,
                response.data.akou_id,
                response.data.ak_dc
              );
              if (blockupdate !== "true") {
                handleSaveTask2(Number(response.data.auto_id));
              }
              onSave();
            }
            if (checkdata?.length === 0) {
              if (blockupdate !== "true") {
                handleSaveTask2(Number(response.data.auto_id));
              }
              onSave();
            }
          } else {
            if (checkdata?.length === 0) {
              handleCreate(
                response.data.auto_id,
                response.data.akou_id,
                response.data.ak_dc
              );
            }
            if (checkdata?.length === 0) {
              handleSaveTask(Number(response.data.auto_id));
            }
          }
          sessionStorage.setItem("tableaction", new Date().getMilliseconds());
          setScheduleErr(false);
          // resetFormState();
          setshowModals(false);
        }
      } catch (err) {
        setIsApiPending(true);
      }
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    }
  };

  let treatmentcategory = localStorage.getItem("SchedulerTreatmentcategory");
  const getData = async () => {
    await axiosInstance.current
      .get(
        `/extapp/doctors/assignCheck?ak_dc=${login.ing_ak_id}&ak_pt=${akpt}&speciality=${Selectspecialty}`
      )
      .then((response) => {
        if (response.status === 200) {
          setAssignCheck(response.data.data);
        }
      })
      .catch((err) => {
        if (err) {
          setAssignCheck([]);
          handleCreate();
          setshowModals(false);
        }
      });
  };

  let resource_ids;
  if (resourceID) {
    resource_ids = resourceID;
  } else if (resource_num) {
    resource_ids = resource_num;
  } else {
    resource_ids = resId;
  }

  const handleSaveTask = async (rids) => {
    let res = rids ? [rids] : [resourceId];
    const json2 = JSON.stringify({
      proms_clinicians_auto_id: res,
    });
    if (res) {
      try {
        const res = await axiosInstance.current.put(
          `/extapp/forms/update_op_notes?resource_id=${resource_ids}&status=1`,
          json2,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 200) {
          // onSave();
          setKkkk("schedulewidgets");
          dispatch(noteRefresh(new Date().getMilliseconds()));
          localStorage.setItem("opnotereff", new Date().getMilliseconds());
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSaveTask2 = async (rids) => {
    let res = rids ? [rids] : [resourceId];
    const jsondata = JSON.stringify({
      proms_clinicians_auto_id: res,
    });
    if (res) {
      try {
        const res = await axiosInstance.current.put(
          `/extapp/forms/update_op_notes?resource_id=${resource_ids}&status=1`,
          jsondata,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 200) {
          // onSave();
          setKkkk("schedulewidgets");
          dispatch(noteRefresh(new Date().getMilliseconds()));
          setshowModal(false);
          // dispatch(noteRefresh(new Date().getMilliseconds()));
          // localStorage.setItem("opnotereff", new Date().getMilliseconds());
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  let akpT = ptId ? ptId : akid;
  const orgString = SelectOrg?.map((org) => org.value).join(",");
  const handleCreate = async (auto_id, akou_id, ak_dc) => {
    const auto_idss = auto_id.toString();
    const resouceString = resource_ids.toString();
    let json;
    if (resource_ids === null || resource_ids === "null") {
      json = JSON.stringify({
        auto_id: auto_idss,
        side: side,
        ak_ou: orgString,
        ak_pt: akpt,
        ak_dc: ak_dc,
        speciality: Selectspecialty,
        assign_note_id: resouceString,
      });
    } else {
      json = JSON.stringify({
        auto_id: auto_idss,
        side: side,
        ak_ou: orgString,
        ak_pt: akpt,
        ak_dc: ak_dc,
        speciality: Selectspecialty,
        assign_note_id: resouceString,
      });
    }
    if (isLoadingdata === "true") {
      setIsLoadingdata(true);
    }
    await axiosInstance.current
      .post(
        `extapp/doctors/assigned_schedulesCreate?ak_dc=${login.ing_ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          if (usedOpNote) {
            setgetOpNotecall(new Date().getMilliseconds());
          }
          if (checkdata?.length === 0) {
            dispatch(noteRefresh(new Date().getMilliseconds()));
            setshowModal(false);
          }
        }
      })
      .catch((err) => {
        if (isLoadingdata === "true") {
          setIsLoadingdata(false);
        }
      });
  };

  useEffect(() => {
    if (popupshow === "true" && Selectspecialty) {
      getData();
    }
  }, [popupshow, Selectspecialty]);

  const [options, setOption] = useState([]);
  useEffect(() => {
    const getSpeciality = async () => {
      try {
        const response = await axiosInstance.current.get(
          `/extapp/doctors/getOpNoteSpecialities/${login.ing_ak_id}`
        );
        const data = response.data.data.findIndex(
          (item) => item.label === "Knee"
        );
        if (data !== -1) {
          response.data.data[data].label = "Knee";
        }
        setOption(response.data.data);
      } catch (err) {}
    };
    getSpeciality();
  }, [login]);

  useEffect(() => {
    const getOrgData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/getAllUserOrganizations?doctor_id=${login.ing_ak_id}&useFor=doctor`
        );
        setOrgdatav(response.data.data);
      } catch (err) {}
    };

    getOrgData();
  }, [login, axiosInstance]);

  const [orgdrop, setOrgdrop] = useState("");
  useEffect(() => {
    if (orgdata?.length === 1) {
      setOrgdrop(orgdata[0]?.label);
    }
  }, [orgdata]);

  const [visibleInput, setVisibleInput] = useState(1);

  const [loaderbtn, setloaderbtn] = useState(false);
  let datacheckdata = checkdata.map((item) => item.assign_id).toString();

  const handleStop = async () => {
    try {
      const result = await axiosInstance.current.put(
        `extapp/doctors/proms_clinicians_schedulerConDis?ak_dc=${login.ing_ak_id}&resource_id=${datacheckdata}&n=-1`
      );
      if (result.status === 200) {
        setshowModal1(false);
        let json = JSON.stringify({
          auto_id: autoid.toString(),
          side: side,
          ak_ou: orgdatava,
          ak_pt: akpt,
          ak_dc: Akdc,
          speciality: Selectspecialty,
          assign_note_id: resource_ids.toString(),
        });
        await axiosInstance.current
          .post(
            `extapp/doctors/assigned_schedulesCreate?ak_dc=${login.ing_ak_id}`,
            json,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (usedOpNote) {
              setgetOpNotecall(new Date().getMilliseconds());
            }
            if (type2 === "postopnote2") {
              setloaderbtn(false);
              onSave();
              dispatch(noteRefresh(new Date().getMilliseconds()));
            } else {
              setloaderbtn(true);
              handleSaveTask();
            }
          });
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseModal = () => {
    setshowModals(false);
    handleClose();
  };

  let sidess = side ? side : opSide;

  const handleKeep = async () => {
    const resouceString = resource_ids.toString();
    let json = JSON.stringify({
      auto_id: autoid.toString(),
      side: sidess,
      ak_ou: orgdatava,
      ak_pt: akpt,
      ak_dc: Akdc,
      speciality: Selectspecialty,
      assign_note_id: resouceString,
    });
    setloaderbtn(true);

    await axiosInstance.current
      .post(
        `extapp/doctors/assigned_schedulesCreate?ak_dc=${login.ing_ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (usedOpNote) {
          setgetOpNotecall(new Date().getMilliseconds());
        }
        //opnote
        if (type2 === "postopnote2") {
          setloaderbtn(false);
          onSave();
          dispatch(noteRefresh(new Date().getMilliseconds()));
        } else {
          setloaderbtn(true);
          setShowPopUp(false);
          handleSaveTask();
        }
      });
  };

  const handleReplace = () => {
    setloaderbtn(true);
    handleStop();
    setshowModals(false);
    setShowPopUp(false);
  };

  const handleDelete = async () => {
    try {
      const result = await axiosInstance.current.delete(
        `/extapp/doctors/proms_clinicians_schedulerDelete?ak_dc=${login.ing_ak_id}&resource_id=${resourceId}`
      );
      if (result.data.status) {
        setMessage(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log("finally api responsed");
    }
  };
  const handleCancel = () => {
    sessionStorage.removeItem("tableaction");
    if (pop) {
      handleDelete();
      setshowModals(false);
      setshowModal1(false);
      setshowModal(false);
      setShowPopUp(false);
      handleSaveTask();
      onSave();
    }
  };

  let set = new Set();

  const handleCloseModals = () => {
    setShowPopUp(false);
  };

  const handleCreates = () => {
    setSubmitdata(new Date().getMilliseconds());
    if (repeatInterval === "") {
      setErr(true);
      setshowModal(true);
      return;
    }
    if (SelectOrg.length === 0) {
      setErr(true);
      setshowModal(true);
      return;
    }
    if (Selectspecialty === "") {
      setErr(true);
      setshowModal(true);
      return;
    }
    if (Selecttreatment === "") {
      setErr(true);
      setshowModal(true);
      return;
    }
    // if (((Selecttreatment === "Surgical") && (Selectspecialty !== "33")) || ((Selecttreatment === "Research") && (Selectspecialty !== "33"))) {
    //   if (primary === "") {
    //     setshowModal(true)
    //     setErr(true);
    //     return;
    //   }
    // }
    // if ((Selecttreatment === "Non-Surgical") || (((Selecttreatment === "Surgical") && (Selectspecialty === "33")) || ((Selecttreatment === "Research") && (Selectspecialty === "33")))) {
    //   if (NonsurgicalInput === "") {
    //     setErr(true)
    //     setshowModal(true)
    //     return
    //   }
    // }
    if (SelectReferdate === "" || SelectReferdate?.length === 0) {
      setErr(true);
      setshowModal(true);
      return;
    }
    if (Schedulerjson.schedules) {
      let filteredData = Schedulerjson.schedules.filter(
        (item) => !(item.guid === 0 && item.value === "")
      );

      const hasConsecutiveSameGuidAndValue = filteredData
        .slice(0, -1)
        .some(
          (item, index) =>
            item.guid === filteredData[index + 1].guid &&
            item.value === filteredData[index + 1].value
        );
      const hasMatchingGuidAndValue = filteredData.some((item, index) =>
        filteredData
          .slice(index + 1)
          .some(
            (otherItem) =>
              item.guid === otherItem.guid && item.value === otherItem.value
          )
      );

      if (hasMatchingGuidAndValue) {
        setScheduleErr(hasMatchingGuidAndValue);
        return;
      } else {
        setScheduleErr(false);
      }
    }
    // if (dropdownValues === "") {
    //   setErr(true)
    //   setshowModal(true)
    //   return
    // }
    // if (formData === "") {
    //   setErr(true)
    //   setshowModal(true)
    //   return
    // }
    const formHasError = validateForm();
    if (formHasError) {
      setErr(true);
      setshowModal(true);
      return;
    }
    setShowPopUp(true);
  };

  return (
    <>
      {loaderbtn ? (
        <LoadingSpinner />
      ) : (
        <>
          {message && <Message message={message} type={messageType} />}
          {/* <button
                className="btn_outline"
                onClick={() => HandleSchedularopen()}
                style={{
                    borderRadius: 20,
                    minHeight: 40,
                    width: 250,
                    fontSize: 14,
                }}
            >
            </button> */}
          <Modal
            show={showModal1}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            // dialogClassName="modal-xl patient_notes_popup modal-dialog-centered"
            dialogClassName="modal-dialog-centered max_width_model"
            className="settings_ppup_main "
          >
            <Modal.Header closeButton style={{ alignItems: "flex-start" }}>
              <div className="col-4">
                <SmallProfilescheduler
                  PostOpNoteshow={PostOpNoteshow}
                  personalData={personalData}
                  fullname={fullname}
                  tAge={tAge}
                  akpt={akpt}
                  ak_pt={ak_pt}
                  showModal={showModal1}
                />
              </div>
              <div className="col-4">
                <Modal.Title
                  className="text-center"
                  style={{ top: "30px", position: "relative" }}
                >
                  <div className="">{"Set PROMs Scheduler"}</div>
                </Modal.Title>
              </div>
            </Modal.Header>
            <Modal.Body className="">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="">
                        <InputField
                          label="PROMs Schedule Title*"
                          value={repeatInterval}
                          type="text"
                          placeholder="Specify"
                          onChange={handleRepeatIntervalChange}
                          styleClass="mw-100 rounded-pill"
                        />
                        {!repeatInterval && (
                          <>
                            {err && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="row">
                      <div className="col-6">
                        <label className="form-label">Organization*</label>
                        <CreatableSelect
                          isClearable={false}
                          isMulti
                          defaultValue={SelectOrg}
                          value={SelectOrg}
                          options={orgdata}
                          onChange={HandleSelectOrg}
                          className="multi_select_drp1"
                          isSearchable={false}
                          overrideStrings={{
                            selectSomeItems: "Select",
                            allItemsAreSelected:
                              orgdata.length > 1
                                ? "All Organization are selected."
                                : orgdrop,
                            selectAll: "Select All",
                            search: "Search",
                          }}
                        />
                        {SelectOrg.length === 0 && (
                          <>
                            {err && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </>
                        )}
                      </div>

                      <div className="col-6">
                        <Dropdown
                          data={options}
                          label="Specialty*"
                          value={Selectspecialty}
                          onChange={(e) => HandleSelectspecialty(e)}
                          styleClass="br_10 mxw_100 mb_20 "
                          placeholder="Select "
                          className="br_20"
                        />
                        {!Selectspecialty && (
                          <>
                            {err && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <Dropdown
                          data={[
                            { value: "Surgical", label: "Surgical" },
                            { value: "Non-Surgical", label: "Non-Surgical" },
                            { value: "Research", label: "Research" },
                          ]}
                          label="Treatment Category*"
                          styleClass=""
                          value={Selecttreatment}
                          onChange={HandleSelecttreatment}
                          placeholder="Select"
                        />
                        {!Selecttreatment && (
                          <>
                            {err && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </>
                        )}
                      </div>

                      {Selecttreatment === "Non-Surgical" ||
                      (Selecttreatment === "Surgical" &&
                        Selectspecialty === "33") ||
                      (Selecttreatment === "Research" &&
                        Selectspecialty === "33") ? (
                        <div className="col-6">
                          <div className="">
                            <InputField
                              label="Primary Diagnosis"
                              value={NonsurgicalInput}
                              type="text"
                              placeholder="Specify"
                              onChange={handleNonsurgicalInput}
                              styleClass="mw-100 rounded-pill"
                            />
                            {/* {!NonsurgicalInput && (
                          <>
                            {err && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </>
                        )} */}
                          </div>
                        </div>
                      ) : null}

                      {(Selecttreatment === "Surgical" ||
                        Selecttreatment === "Research") &&
                        (Selectspecialty === "4" ||
                          Selectspecialty === "6" ||
                          Selectspecialty === "5" ||
                          Selectspecialty === "22" ||
                          Selectspecialty === "21" ||
                          Selectspecialty === "23") && (
                          <>
                            <div className="col-6">
                              {Selectspecialty === "4" && (
                                <>
                                  <label
                                    className={`form-label ${addOpStyles.form_label}`}
                                  >
                                    Primary Diagnosis
                                  </label>
                                  <Dropdown
                                    data={[
                                      {
                                        value: "Rotator Cuff Repair",
                                        label: "Rotator Cuff Repair",
                                      },
                                      {
                                        value: "Instability",
                                        label: "Instability",
                                      },
                                      {
                                        value: "Scapula Fracture",
                                        label: "Scapula Fracture",
                                      },
                                      {
                                        value: "Proximal humerus fracture",
                                        label: "Proximal humerus fracture",
                                      },
                                      {
                                        value: "Clavicle fracture",
                                        label: "Clavicle fracture",
                                      },
                                      {
                                        value: "Arthroplasty",
                                        label: "Arthroplasty",
                                      },
                                      {
                                        value: "ACJ",
                                        label: "ACJ",
                                      },
                                      {
                                        value: "Biceps tendon disorder",
                                        label: "Biceps tendon disorder",
                                      },
                                      {
                                        value: "Others",
                                        label: "Other",
                                      },
                                    ]}
                                    label={""}
                                    styleClass=""
                                    value={primary}
                                    onChange={handleprimary}
                                    placeholder={" Please select"}
                                  />

                                  {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                                </>
                              )}
                              {Selectspecialty === "6" && (
                                <>
                                  <label
                                    className={`form-label ${addOpStyles.form_label}`}
                                  >
                                    Primary Diagnosis
                                  </label>
                                  <Dropdown
                                    data={[
                                      {
                                        value: "Arthroscopy",
                                        label: "Arthroscopy",
                                      },
                                      {
                                        value: "Arthroplasty",
                                        label: "Arthroplasty",
                                      },
                                      {
                                        value: "Fracture Surgery",
                                        label: "Fracture Surgery",
                                      },
                                      {
                                        value: "Other Hip Preservation Surgery",
                                        label: "Other Hip Preservation Surgery",
                                      },
                                      {
                                        value: "Others",
                                        label: "Other",
                                      },
                                    ]}
                                    label={""}
                                    styleClass=""
                                    value={primary}
                                    onChange={handleprimary}
                                    placeholder={" Please select"}
                                  />
                                  {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                                </>
                              )}
                              {Selectspecialty === "5" && (
                                <>
                                  <label
                                    className={`form-label ${addOpStyles.form_label}`}
                                  >
                                    Primary Diagnosis
                                  </label>
                                  <Dropdown
                                    data={[
                                      {
                                        value: "Arthritis",
                                        label: "Arthritis",
                                      },
                                      {
                                        value: "Instability",
                                        label: "Instability",
                                      },
                                      {
                                        value: "Patellar instability",
                                        label: "Patellar instability",
                                      },
                                      {
                                        value: "Meniscal injury",
                                        label: "Meniscal injury",
                                      },
                                      {
                                        value: "Fracture",
                                        label: "Fracture",
                                      },
                                      {
                                        value: "Septic knee",
                                        label: "Septic knee",
                                      },
                                      {
                                        value: "Osteonecrosis",
                                        label: "Osteonecrosis",
                                      },
                                      {
                                        value: "Revision arthroplasty",
                                        label: "Revision arthroplasty",
                                      },
                                      {
                                        value: "Chondral injury",
                                        label: "Chondral injury",
                                      },
                                      {
                                        value: "Others",
                                        label: "Other",
                                      },
                                    ]}
                                    label={""}
                                    styleClass=""
                                    value={primary}
                                    onChange={handleprimary}
                                    placeholder={" Please select"}
                                  />
                                  {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                                </>
                              )}
                              {Selectspecialty === "22" && (
                                <>
                                  <label
                                    className={`form-label ${addOpStyles.form_label}`}
                                  >
                                    Primary Diagnosis
                                  </label>
                                  <Dropdown
                                    data={[
                                      {
                                        value: "Fracture",
                                        label: "Fracture",
                                      },
                                      {
                                        value: "Instability",
                                        label: "Instability",
                                      },
                                      {
                                        value: "Arthritis",
                                        label: "Arthritis",
                                      },
                                      {
                                        value: "Failed arthroplasty",
                                        label: "Failed arthroplasty",
                                      },
                                      {
                                        value: "Ligament/Tendon injury",
                                        label: "Ligament/Tendon injury",
                                      },
                                      {
                                        value: "Nerve entrapment",
                                        label: "Nerve entrapment",
                                      },
                                      {
                                        value: "Others",
                                        label: "Other",
                                      },
                                    ]}
                                    label={""}
                                    styleClass=""
                                    value={primary}
                                    onChange={handleprimary}
                                    placeholder={" Please select"}
                                  />
                                  {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                                </>
                              )}
                              {Selectspecialty === "21" && (
                                <>
                                  <label
                                    className={`form-label ${addOpStyles.form_label}`}
                                  >
                                    Primary Diagnosis
                                  </label>
                                  <Dropdown
                                    data={[
                                      {
                                        value: "Fracture/Dislocation",
                                        label: "Fracture/Dislocation",
                                      },
                                      {
                                        value: "Arthritis",
                                        label: "Arthritis",
                                      },
                                      {
                                        value: "Avascular necrosis",
                                        label: "Avascular necrosis",
                                      },
                                      {
                                        value: "Charcot foot",
                                        label: "Charcot foot",
                                      },
                                      {
                                        value: "Amputation",
                                        label: "Amputation",
                                      },
                                      {
                                        value: "Arthroscopy",
                                        label: "Arthroscopy",
                                      },
                                      {
                                        value: "Instability",
                                        label: "Instability",
                                      },
                                      {
                                        value: "Deformity",
                                        label: "Deformity",
                                      },
                                      {
                                        value: "Achilles tendon pathology",
                                        label: "Achilles tendon pathology",
                                      },
                                      {
                                        value: "Others",
                                        label: "Other",
                                      },
                                    ]}
                                    label={""}
                                    styleClass=""
                                    value={primary}
                                    onChange={handleprimary}
                                    placeholder={" Please select"}
                                  />
                                  {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                                </>
                              )}
                              {Selectspecialty === "23" && (
                                <>
                                  <label
                                    className={`form-label ${addOpStyles.form_label}`}
                                  >
                                    Primary Diagnosis
                                  </label>
                                  <Dropdown
                                    data={[
                                      {
                                        value: "Fracture/Dislocation",
                                        label: "Fracture/Dislocation",
                                      },
                                      {
                                        value:
                                          "Instability and ligamentous injury",
                                        label:
                                          "Instability and ligamentous injury",
                                      },
                                      {
                                        value: "Tendon injury",
                                        label: "Tendon injury",
                                      },
                                      {
                                        value: "Compression neuropathy",
                                        label: "Compression neuropathy",
                                      },
                                      {
                                        value: "Nerve injury",
                                        label: "Nerve injury",
                                      },
                                      {
                                        value: "Arthritis",
                                        label: "Arthritis",
                                      },
                                      {
                                        value: "Others",
                                        label: "Other",
                                      },
                                    ]}
                                    label={""}
                                    styleClass=""
                                    value={primary}
                                    onChange={handleprimary}
                                    placeholder={" Please select"}
                                  />
                                  {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                                </>
                              )}

                              {primary === "Others" && (
                                <>
                                  <div className="col-12">
                                    <div className="">
                                      <InputField
                                        value={Othersinputfield}
                                        type="text"
                                        placeholder="Specify"
                                        onChange={handleOthersinputfield}
                                        styleClass="mw-100 rounded-pill"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                    </div>

                    <div className="row">
                      {/* <div className="col-6">
                        <label className="form-label">Alert</label>
                        <CreatableSelect
                          isClearable
                          isMulti
                          value={SelectAlert}
                          onChange={HandleSelectAlert}
                          options={alertOptions}
                          className="multi_select_drp1"
                          isSearchable={false}
                        />
                      </div> */}

                      <div className="col-6">
                        <label className="form-label">
                          Alert For Overdue Forms
                        </label>
                        <CreatableSelect
                          isClearable
                          isMulti
                          value={SelectAlertOver}
                          options={alertOverdueOptions}
                          onChange={HandleSelectAlertOver}
                          className="multi_select_drp1"
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row">
                    <div className="col-1">
                      <div class="horizontal-line"></div>
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className="col-6">
                          <Dropdown
                            data={
                              type === "postopnote"
                                ? referenceDate1
                                : BlockReference
                                ? referenceDate2
                                : referenceDate
                            }
                            label="Reference Date*"
                            styleClass=""
                            value={SelectReferdate}
                            onChange={HandleSelectReferdate}
                            placeholder="Select"
                          />
                          {!SelectReferdate || SelectReferdate?.length == 0 ? (
                            <>
                              {err && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <label>Set Schedule*</label>
                      <div>
                        <DropdownList
                          setFormData={setFormData}
                          setDropdownValues={setDropdownValues}
                          submitdata={submitdata}
                          setVisibleInput={setVisibleInput}
                        />
                      </div>
                    </div>

                    {formData && (
                      <>
                        {setSheduleerr && (
                          <span className="text-danger text-center" style={{ marginTop: '10px' }}>
                            Days, Weeks, Months or Years values should not be
                            the same.
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="text-end">
                  <button
                    className="btn_outline"
                    onClick={() => handleCreates()}
                    style={{
                      borderRadius: 20,
                      minHeight: 40,
                      width: 140,
                      fontSize: 14,
                    }}
                    disabled={isApiPending}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showModals}
            // show={true}
            onHide={handleCancel}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-lg patient_notes_popup modal-popup modal-dialog-centered"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-left pe-5">
                <div className="fw-bold">
                  This patient has an existing{" "}
                  {notresetspecialty == 4
                    ? "Shoulder"
                    : notresetspecialty == 6
                    ? "Hip"
                    : notresetspecialty == 21
                    ? "Foot and Ankle"
                    : notresetspecialty == 22
                    ? "Elbow"
                    : notresetspecialty == 5
                    ? "Knee"
                    : notresetspecialty == 23
                    ? "Hand and Wrists"
                    : notresetspecialty == 33
                    ? "General"
                    : ""}{" "}
                  PROMs Schedule.Do you want to replace it with the one you've
                  created?
                </div>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="">
              {checkdata?.length !== 0 ? (
                <>
                  <div className="col-12 proms_Scheduler">
                    <div className="row" style={{ flexDirection: "row" }}>
                      <div style={{ width: "30%" }}>
                        <span>
                          <strong>Title : </strong>
                        </span>
                      </div>
                      <div className="col">
                        {checkdata && checkdata?.length > 0 ? (
                          <>
                            {Array.isArray(checkdata) &&
                            checkdata?.length > 0 ? (
                              <>
                                {checkdata.map((item, index) => (
                                  <>
                                    {item.label}
                                    {index !== checkdata?.length - 1 && ", "}
                                  </>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 proms_Scheduler">
                    <div className="row">
                      <div style={{ width: "30%" }}>
                        <span>
                          <strong>Treatment Side : </strong>
                        </span>
                      </div>
                      <div className="col">
                        {Array.isArray(checkdata) && checkdata?.length > 0 ? (
                          <>
                            {checkdata.map((item, index) =>
                              item.side.map((val) => {
                                if (val === "1" || val === null)
                                  set.add("Right");
                                if (val === "2") set.add("Left");
                                {
                                  /* if (val === null) set.add("Right") */
                                }
                              })
                            )}
                            {Array.from(set).join(",")}
                          </>
                        ) : (
                          "Right"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 proms_Scheduler">
                    <div className="row">
                      <div style={{ width: "30%" }}>
                        <span>
                          <strong>Primary Diagnosis : </strong>
                        </span>
                      </div>
                      <div className="col">
                        {Array.isArray(checkdata) && checkdata?.length > 0 ? (
                          <>
                            {checkdata.map((item, index) => (
                              <>
                                {item.primary_diagnosis}
                                {index !== checkdata?.length - 1 && ", "}
                              </>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                onClick={handleCancel}
                value="Cancel"
                buttonStyle="btn_outline ms-2 mb-3 "
                width="170px"
                height={"40px"}
              />

              <Button
                onClick={() => handleKeep()}
                value="keep Both"
                buttonStyle="btn_outline ms-2 mb-3 "
                width="170px"
                height={"40px"}
              />

              <Button
                onClick={handleReplace}
                value="Replace"
                buttonStyle="btn_outline ms-2 mb-3 "
                width="170px"
                height={"40px"}
              />
            </Modal.Footer>
          </Modal>
          <Modal
            centered
            show={showPopUp}
            onHide={handleCloseModals}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-s patient_notes_popup"
          >
            <Modal.Header>
              <Modal.Title className="text-center">
                <b>{"Alert"}</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="text-center">
                <p>
                  <strong>
                    Once saved, the schedule is final and cannot be edited.
                  </strong>{" "}
                </p>
                <p>
                  <strong>Do you want to proceed?</strong>
                </p>
              </div>
            </Modal.Body>
            {/* <br></br> */}
            <Modal.Footer
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div className="row">
                <div className="col-6">
                  <Button
                    value="No"
                    radius="20px"
                    width="140px"
                    fontSize="14px"
                    // buttonStyle="btn_outline f_600"
                    border=""
                    color=""
                    minHeight="40px"
                    height="40px"
                    borderRadius="50px"
                    background="transparent"
                    onClick={handleCloseModals}
                  />
                </div>

                <div className="col-6">
                  <ButtonLoader
                    value="Yes"
                    radius="20px"
                    width="140px"
                    fontSize="14px"
                    // buttonStyle="btn_outline f_600"
                    border=""
                    color=""
                    minHeight="40px"
                    height=""
                    onClick={handleSave}
                    disabled={isApiPending}
                  />
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}
