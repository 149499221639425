import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import useAxios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import Message from "../../custom/toster/Message";
export default function MultiSelect({
  setMultidata,
  data,
  viewall,
  orgid,
  patienresourcevalue,
  rule_id,
}) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  const [resourcetype, setResourceType] = useState([]);
  const [selectresource, setselectresource] = useState([]);
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState();
  const [show, setShow] = useState(false);
  function getUniqueOptions(apiData, options, field) {
    const matchedValues = apiData.map((item) =>
      field === "org" ? item.label : item.value
    );
    const uniqueOptions = options.filter(
      (option) =>
        !matchedValues.includes(field === "org" ? option.label : option.value)
    );
    return uniqueOptions;
  }

  const handleResource = (e) => {
    setResourceType(e);
    if (viewall !== "true") {
      if (patienresourcevalue === "allResources") {
        setMultidata([...data, ...e]);
      } else {
        setMultidata(e);
      }
    }
  };
  useEffect(() => {
    // if(viewall==="true"){
    if (viewall !== "true") {
      if (patienresourcevalue === "allResources") {
        setMultidata([...data, ...resourcetype]);
      } else {
        setMultidata(resourcetype);
      }
    }
    if (viewall === "true") {
      if (data) {
        setResourceType(data);
      }
    }
  }, [data.length]);
  const [sendtyping, setsendtyping] = useState("");
  const handleInputChange = (inputValue) => {
    setLastTypingTime(new Date().getTime());
    setsendtyping(inputValue);
    // Add your logic to create a new option
    if (patienresourcevalue !== "allResources" && viewall !== "true") {
      if (inputValue < 3) {
        setselectresource([]);
      }
    }
  };
  useEffect(() => {
    if (orgid && patienresourcevalue === "allResources")
      axiosInstance.current
        .get(`/extapp/doctors/restype_of_org?org_id=${orgid}`)
        .then((response) => {
          const list = response.data.data
            .filter((item) => item.alias !== "")
            .map((item) => {
              return {
                label: item.alias,
                value: item.type,
              };
            });
          let uniqdata = getUniqueOptions(data, list);
          setselectresource(uniqdata);
        });
  }, [orgid, patienresourcevalue]);
  console.log(data, "jjdata");
  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const GetDiagnosis = (searchValue) => {
          try {
            // const searchValueArray = Array.isArray(searchValue) ? searchValue.map(item => item.value) : [searchValue.value];
            // const searchName = searchValueArray.join(',');
            axiosInstance.current
              .get(
                `/extapp/doctors/allHcpsOfOrgs?ak_id=${login.ing_ak_id}&keyword=${sendtyping}`
              )
              .then((response) => {
                const options = response.data.result;
                let uniqdata = getUniqueOptions(data, options, "org");
                let datas=uniqdata.map((item)=>{
                  return{
                  label: item.label,
                  org_id:item.org_id,
                  doc_id:item.value,
                  value:item._id

                  }
                })
                setselectresource(datas);
                // setDiagnosisidc(options);
              });
          } catch (error) {
            console.log(error);
          }
        };

        if (sendtyping.length >= 3 && patienresourcevalue !== "allResources") {
          GetDiagnosis();
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [sendtyping, patienresourcevalue]);

  const handleRequest = async (org_id, dc_id) => {
    setShow(false);
    try {
      const response = await axiosInstance.current.put(
        `/extapp/doctors/sendPendingReq?rule_id=${rule_id}&ak_id_to=${dc_id}&accept=0&akou_id=${org_id}`
      );

      if (response.status === 200) {
        // dispatch(noteRefresh(new Date().getMilliseconds()));
        setMessageType("success");
        setMessage("Request approved");
        setShow(true);
        setTimeout(() => {
          setMessage(false);
          setShow(false);
        }, 3000);
      }
      setTimeout(() => {
        // setMessage(false);
      }, 3000);
    } catch (error) {
      setMessage("Request not approved");
      setMessageType("error");
      setShow(true);
      setTimeout(() => {
        setMessage(false);
        setShow(false);
      }, 3000);
    } finally {
    }
  };
  return (
    <>
    {show && <Message message={message} type={messageType} />}
      <CreatableSelect
        isCreatable={false}
        isMulti
        value={resourcetype}
        onChange={handleResource}
        options={selectresource}
        className="multi_select_drp11"
        onInputChange={handleInputChange}
        defaultMenuIsOpen={false}
        isValidNewOption={() => false}
        isDisabled={viewall === "true" ? true : false}
      />
      {patienresourcevalue !== "allResources" && viewall !== "true" ? (
        <span className="fst-italic fw-light" style={{ color: "#3F2783" }}>
          {"Search with minimum of three characters"}
        </span>
      ) : null}
      <hr></hr>
      <div>
        {viewall !== "true" && (
          <>
            <label>
              Already shared{" "}
              {patienresourcevalue !== "allResources" ? "Hcp (s)" : "Resource"}
            </label>
            <table className="multitable">
              <thead>
                <tr>
                  <th className="multitableth">
                    <strong>
                      {patienresourcevalue !== "allResources"
                        ? "Hcp (s)"
                        : "Resource"}
                    </strong>
                  </th>
                  {patienresourcevalue !== "allResources" && (
                    <th className="multitableth">
                      <strong>Status</strong>
                    </th>
                  )}
                  {patienresourcevalue !== "allResources" && (
                    <th className="multitableth">
                      <strong>Action</strong>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <td className="multitabletd">
                      <div>
                        {Array.isArray(item.label)
                          ? item.label.join(", ")
                          : item.label}
                      </div>
                    </td>
                    {patienresourcevalue !== "allResources" && (
                      <td className="multitabletd">
                        <div>
                          {item.accept === -1 && (
                            <span className="fw-bolder text-danger ">
                              Rejected
                            </span>
                          )}
                          {item.accept === 1 && (
                            <span className="fw-bolder text-success ">
                              Approved
                            </span>
                          )}
                          {item.accept === 0 && (
                            <span className="fw-bolder text-warning ">
                              Pending
                            </span>
                          )}
                        </div>
                      </td>
                    )}
                 {patienresourcevalue !== "allResources" &&(
                   <td className="multitabletd">
                   {patienresourcevalue !== "allResources" &&
                 item.accept === -1 ? (
                     <div>
                       <button
                         className="btn_green me-4"
                         style={{ width: "113px" }}
                         onClick={() =>
                           handleRequest(item.org_id, item.value)
                         }
                       >
                         Re-request
                       </button>
                     </div>
                      ) : null}
                   </td>
                 )}
                     
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
}
