// import { useEffect, useRef } from "react";
// import axios from "axios";

// import { useKeycloak } from "@react-keycloak/web";

// const useAxiosUpload = (baseURL) => {

//   const axiosInstanceUpload = useRef();

//   const { keycloak, initialized } = useKeycloak();
//   const kcToken = keycloak.token;

//   useEffect(() => {
//     axiosInstanceUpload.current = axios.create({
//       baseURL: process.env.REACT_APP_APIURLUPLOAD,
//       headers: {
//         Authorization: initialized ? `Bearer ${kcToken}` : undefined,
//       },
//     });

//     return () => {
//       axiosInstanceUpload.current = undefined;
//     };
//   }, [baseURL, initialized, kcToken]);

//   return axiosInstanceUpload ;
// };
// export default useAxiosUpload;

import { useEffect, useRef } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
const useAxiosUpload = (baseURL) => {
  const axiosInstanceUpload = useRef();
  const { keycloak, initialized } = useKeycloak();
  const interval = setInterval(5, 60 * 1000);
  useEffect(() => {
  
    const createAxiosInstance = () => {
      axiosInstanceUpload.current = axios.create({
        baseURL: process.env.REACT_APP_APIURLUPLOAD,
        headers: {
          Authorization:
            initialized && keycloak.token
              ? `Bearer ${keycloak.token}`
              : undefined,
        },
      });
  
    };
    createAxiosInstance();

  }, [interval]);
  return axiosInstanceUpload;
};
export default useAxiosUpload;
