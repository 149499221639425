import { combineReducers } from "redux";
import userIdReducer from "./userIdReducer";
import userMailReducer from "./userMailReducer";
import loaderReducer from "./loaderReducer";
import loginData from "./loginData";
import timeZone from "./timeZone";
import noteRef from "./noteRef";
import chatRef from "./chatRef";
import accessTokens from "./accessTokens";
import featuresReducer from "./featuresReducer";
import  adultKneeScoreReducer from "./adultKneeScoreReducer";
import hipScoreReducer from "./hipScoreReducer";
import FootAnkleReducer from "./footAnkleReducer";
import PediaKneeReducer from "./pediaKneeReducer";
import KneeFeedbackReduce from "./KneeFeedbackReduce";
import ElbowReducer from "./elbowReducer";

import ShoulderScoringReducer from './ShoulderScoringReducer';
import HandWristReducer from "./handWristReducer";
import GeneralFormReduces from "./GeneralFormReduces"
import patientDetails from "./patientDetails";
import LabralTearReducer from "./LabralTearReducer"
import orgReducer from "./orgReducer";
import AddPatientReducer from "./AddPatientReducer";
const mainReducer = combineReducers({
  userDetails: userIdReducer,
  userMailDetails: userMailReducer,
  loaderReducer,
  loginData,
  timeZone,
  noteRef,
  patientDetails,
  chatRef,
  featuresData:featuresReducer,
  accessTokens,
  adultKneeScore: adultKneeScoreReducer,
  hipScoreReducer,
  footAnkle : FootAnkleReducer,
  pediaKnee : PediaKneeReducer,
  shoulderScore:ShoulderScoringReducer,
  elbowScore:ElbowReducer,
  handWrist:HandWristReducer,
  KneeFeedbackReduce:KneeFeedbackReduce,
  GeneralFormReduces,
  LabralTearReducer,
  orgReducer,
  AddPatientReducer
});

export default mainReducer;
