import React, { useState } from "react";
import viewIcon from "../../../images/eye-icon.svg";
import deleteIcon from "../../../images/delete-icon.svg";
import hcpIcon from "../../../images/hcp.svg";
import patientIcon from "../../../images/icon2-fillblue.svg";
import resourceIcon from "../../../images/resource.svg";
import { useSelector,useDispatch } from "react-redux";
import { UserDateFormat } from "../../../utilities/DateFormate";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import Button from "../../custom/button/Button";
import MultiSelect from "./MultiSelect";
import PatientModel from "./PatientModel";
import ViewallModel from "./ViewallModel";
import HcpPatientModel from "./HcpPatientModel";
import useAxios from "../../../axiosinstance";
import { noteRefresh } from '../../../redux/actions';
export const ShareTable = ({ data, id }) => {
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
    const[rule_idvalue,setrule_idvalue]=useState("")
  const submitDelete = async() => {
    try {
      const response = await axiosInstance.current.put(
        `/extapp/doctors/stopRule?rule_id=${rule_idvalue}&active=-1&ak_id=${login.ing_ak_id}`)
        if (response) {
          
          setDisplayConfirmationModalDelete(false);
          dispatch(noteRefresh(new Date().getMilliseconds()));
        }
      }catch(err){
        console.log((err));
      }
 
  }
  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };

  const showDeleteModal = (id, title) => {
    setrule_idvalue(id)
    // setShowtitle(title);
    // setnotedeleteid(id);
    setDisplayConfirmationModalDelete(true);
  };
  const [Multidata, setMultidata] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);


  const [orgid, setOrgid] = useState("")
  const [allPatients, setallPatients] = useState([])
  const [rule_id,setrule_id]=useState("")
  const handleShow = (orgid, allPatients,rule_id) => {
    setOrgid(orgid)
    setallPatients(allPatients)
    setrule_id(rule_id)
    setShowModal(true)
  }
  const [viewdata, setViewdata] = useState({
    rule_name: "",
    org_name: "",
    hcp_ids: [],
    allResources: []
  })
console.log(orgid,"checkingorgid")
  const handleView = (rule_name, org_name, hcp_ids, allResources) => {
    setViewdata({
      rule_name: rule_name,
      org_name: org_name,
      hcp_ids: hcp_ids,
      allResources: allResources
    })
    setShowModal1(true)
  }
const [patienresource,setPatienresource]=useState([])
const [patienresourcevalue,setPatienresourcevalue]=useState("")
  const handleOpenall=(v,data,orgid,rule_id)=>{
    setPatienresourcevalue(v)
    setPatienresource(data)
    setOrgid(orgid)
    setrule_id(rule_id)
    setShowModal2(true)
    
  }
  return (
    <>
      <div className="SharetableContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {data?.length !== 0 && (
                <table
                  role="table table-responsive"
                  className="custom-tbl my_clinic"
                >
                  <thead>
                    {id === "Share" ? (
                      <tr role="row">
                        <th colSpan={1} role="columnheader" className="col-orgname">
                          HCP
                        </th>
                       
                        <th colSpan={1} role="columnheader" className="col-status">
                          Organization
                        </th>
                        {/* <th colSpan={1} role="columnheader" className="col-status">
                          Created date
                        </th>
                        <th colSpan={1} role="columnheader" className="col-status">
                          Actions
                        </th> */}
                      </tr>
                    ) : (
                      <tr role="row">
                        <th colSpan={1} role="columnheader" className="col-orgid">
                          Rule Title
                        </th>
                        <th colSpan={1} role="columnheader" className="col-orgname">
                          HCP(s)
                        </th>
                        <th colSpan={1} role="columnheader" className="col-status">
                          Patients
                        </th>
                        <th colSpan={1} role="columnheader" className="col-status">
                          Resources
                        </th>
                        <th colSpan={1} role="columnheader" className="col-status">
                          Organization
                        </th>
                        <th colSpan={1} role="columnheader" className="col-status">
                          Actions
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {id === "Share" ? (
                      <>
                        {data.map((item) => (
                          <tr role="row">
                            <td role="cell" className="col-orgid">
                              {item.fullName}
                            </td>
                            
                            <td role="cell" className="col-orgname">
                              {item.orgName}
                            </td>
                            {/* <td role="cell" className="col-status">
                              {UserDateFormat(
                                item.created_at,
                                login.ing_date_formate,
                                login.ing_timezone
                              )}
                            </td> */}

                            {/* <td role="cell" className="col-orgname ">
                              <img src={viewIcon} alt="Icon" />

                              <img className="ms-3" src={deleteIcon} alt="Icon" onClick={() => showDeleteModal()} />
                            </td> */}
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        {data.map((item, index) => (
                          <tr role="row">
                            <td role="cell" className="col-orgid">
                              {item.rule_name}
                            </td>
                            <td role="cell" className="col-orgname">
                              <img
                                src={hcpIcon}
                                alt="Icon"
                                className="c_pointer"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="HCP(s)" onClick={()=>handleOpenall("hcp_ids",item.hcp_ids,item.org_id,item.rule_id)}>
                              </img>
                              
                              {/* <MultiSelect
                          data={item.hcp_ids}
                          setMultidata={setMultidata}
                          /> */}
                            </td>
                            <td role="cell" className="col-status">
                              <img
                                className="c_pointer"
                                src={patientIcon}
                                alt="Icon"
                                onClick={() => handleShow(item.org_id, item.allPatients,item.rule_id)}
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Patients"

                              />
                            </td>
                            <td role="cell" className="col-orgname " id={index}>
                              <img
                                className="c_pointer"
                                src={resourceIcon}
                                alt="Icon"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Resources" onClick={()=>handleOpenall("allResources",item.allResources,item.org_id,item.rule_id)}>

                                </img>
                              
                              {/* <MultiSelect
                                data={item.allResources}
                                setMultidata={setMultidata}
                              /> */}

                            </td>
                            <td role="cell" className="col-orgname ">
                              {item.org_name}
                            </td>
                            <td role="cell" className="col-orgname ">
                              <img className="c_pointer" src={viewIcon} alt="Icon" onClick={() => handleView(item.rule_name, item.org_name, item.hcp_ids, item.allResources)} />

                              <img className=" c_pointer ms-1" src={deleteIcon} alt="Icon" onClick={() => showDeleteModal(item.rule_id)} />
                            </td>
                          </tr>
                        ))}
                      </>

                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <DeleteConfirmation
            showModal={displayConfirmationModalDelete}
            confirmModal={submitDelete}
            hideModal={hideConfirmationModal}
            //   message={"Are you sure these patient details are correct?"}
            type="task_id"

          />
        </div>
      </div>
      <PatientModel modelOpen={showModal} setShowModal={setShowModal} type={8} orgid={orgid} allPatients={allPatients} setOrgid={setOrgid} rule_id={rule_id}/>
      <ViewallModel modelOpen={showModal1} setShowModal={setShowModal1} viewdata={viewdata} />
      <HcpPatientModel modelOpen={showModal2} setShowModal={setShowModal2} patienresource={patienresource} patienresourcevalue ={patienresourcevalue} orgid={orgid} rule_id={rule_id} setOrgid={setOrgid}/>
    </>
  );
};
